/*
 * Color Palette:
 *   http://colormind.io
 * #144d97 - Dark Blue
 * #137bb0 - Mid Blue
 * #28c2d3 - Light Blue
 * #f1d272 - Pale Yellow
 * #e74e4e - Pale Red
 */

body {
  background-color: black;
}

.Home {
  background-color: black;
  color: #4BDD3F;
  min-height: 100vh;
  padding-top: 30px;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Source Code Pro', monospace;
}

.Header {
  height: 15vh;
  padding-left: 50px;
  padding-right: 50px;
  overflow: hidden;
}

.Headshot {
  height: 100%;
  margin-left: 30px;
  transition: transform 0.2s;
}

.Headshot:hover {
  transform: rotateY(180deg);
}

.Card-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.Card {
  background-color: black;
  min-width: 340px;
  width: 45%;
  max-width: 400px;
  height: 200px;
  border: 0;
  margin: 2vh;
  transition: transform 0.2s, opacity 0.2s;
}

.Card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Card a {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.card-text {
  background-color: black;
  text-align: center;
  user-select: none;
}

.Card:active {
  opacity: 0.7;
  transform: rotateY(15deg) rotateX(-15deg) translateX(-10px);
}

.Hub-header {
  color: #f1d272;
  font-weight: bold;
}
