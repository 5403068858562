/*
 * Color Palette:
 * #c00000 - UE Red
 * #ff3334 - Deep Carmine Pink
 * #ff6f77 - Begonia
 * #ffbbc1 - Light Pink
 * #ffdee3 - Piggy Pink
 * #ff8896 - Tulip
 */

.Valentine-app {
  font-stretch: expanded;
  width: 100vw;
  height: 100vh;
}

.Valentine-app-container {
  background-color: #ff3334;
  height: 100%;
}

.Valentine-image {
  width: 200px;
  margin-bottom: 20px;
  /* height: 200px; */
  animation: rotateAnimation 10s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}

.Valentine-form {
  background-color: #ff6f77;
  color: black;
  font-size: larger;
  padding: 20px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Valentine-question {
  margin-bottom: 20px;
}

.Valentine-form-submit {
  color: black;
  background-color: #ff3334;
  border-color: #ff3334;
  margin-bottom: 20px;
}

.Valentine-form-submit:hover {
  color: black;
  background-color: #ff8896;
  border-color: #ff8896;
}

.Valentine-form-submit:active {
  color: black;
  background-color: #ff8896;
  border-color: #ff8896;
}

@keyframes rotateAnimation {
	from {transform: rotateY(0deg);}
	to {transform: rotateY(360deg);}
}
