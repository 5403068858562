/*
 * Color Palette:
 *   https://colorpalettes.net/color-palette-3862/
 * #213036 - Dark Blue
 * #43a9f5 - Light Blue
 * #265500 - Dark Green
 * #3ded97 - Lime Green
 * #f58f92 - Salmon
 * #b7241b - Red
 */

 /*
  * Z-Index Mapping:
  * 100 - Snowflakes
  * 200 - App Container
  */

.App {
  text-align: center;
  font-stretch: expanded;
}

.App-header {
  background-color: #213036;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.NavLink {
  color: #3ded97;
}

.NavLink:hover {
  color: #b7241b;
}

.App-scrollable {
  width: 100%;
  height: 100%;
  z-index: 200;
}

.App-container {
  width: 90%;
  max-width: 1080px;
  height: 100%;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.App-padding {
  height: 56px;
}

.Toggle-button {
  margin-top: 8px;
  margin-bottom: 8px;
}

.Header-big {
  color: #3ded97;
  font-weight: bold;
  padding-top: 10px;
}

.Header-small, .NPC-text {
  color: #3ded97;
}

.Red-fancy {
  color: #b7241b;
  font-style: italic;
}

.Header-image {
  width: 100%;
  padding: 0;
}

.Section-block {
  background-color: #213036;
}

.Character-image {
  padding-right: 2vw;
}

.Giftlist-image {
  width: 70%;
  padding-left: 2vw;
}

.Untwirled-image {
  width: 50%;
  padding-right: 2vw;
}

.Santavator-panel-image {
  width: 50%;
  padding-left: 2vw;
}

.Text-block {
  color: #43a9f5;
  text-align: left;
  font-size: medium;
  font-weight: bold;
  text-align: justify;
  word-break: keep-all;
}

ol, .Narrator-block {
  color: #f58f92;
  text-align: left;
  font-size: medium;
  font-weight: bold;
  text-align: justify;
  word-break: keep-all;
}

.Terminal-block {
  min-width: 100%;
  text-align: left;
  word-wrap: break-word;
  margin-bottom: 15px;
}

.Hex-block {
  color: white;
  font-size: large;
  font-weight: bold;
  font-family: monospace;
  white-space: pre-wrap;
}

.tab {
  display: inline-block;
  margin-left: 20px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
